.widget {
    width: 430px;
    height: 242px;
    background-color: #F1F4F8;
    margin: 31px 0px;
    padding: 34px 39px 38px 33px;
    border-radius: 5px;
}

.widget-container p {
    font-size: 12px;
    text-align: justify;
}

.description-container {
    padding: 19px 0px 24px 0px;
}

.btn-pagar {
    font-size: 14px !important;
    font-family: 'Rubik', sans-serif;
    font-weight: normal;
    width: 132px;
    height: 40px;
  }

.actions-placeholder {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    width: 100%;
  }

  .actions-placeholder p {
    font-size: 14px !important;
    font-family: 'Rubik', sans-serif;
    font-weight: 500;
  }