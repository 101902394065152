.contactanos-text {
  font-size: 14px; 
}

.contactanos-container {
  position: absolute;
  right: 0;
  top: 28px;
}

.custom-button, input[type="submit"], input[type="reset"] {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}