.menu {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 80px;
  margin: 0;
  height: 100vh;
  z-index: 1000;
}

.menu-header {
  float: left;
  margin-top: 20px;
  width: 100%;
}

.menu-body {
  float: left;
  margin-top: 200px;
  width: 100%;
}

.menu-footer {
  position: fixed;
  bottom: 0;
  width: 80px;
}