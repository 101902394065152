.submenuConfig-container {
  width: 100%;
  height: 109px;
  background-color: #F1F4F8;
  position: relative;
}

.submenuConfig-container > .title-container {
  position: absolute;
  left: 95px;
  top: 29px;
}

.submenuConfig-container > .options-container {
  position: absolute;
  left: 95px;
  bottom: 14px;
  height: 35px;
  font-size: 16px;
  font-weight: 600;
}

.options-container > .active {
  border-radius: 17.5px;
  height: 100%;
  background-color: rgba(18,37,68,0.15);
  cursor: pointer;
}

.options-container > .option-title {
  padding-right: 1em;
  padding-left: 1em;
  float: left;
  height: 100%;
}