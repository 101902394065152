.card-container {
  width: 224px;
  height: 146px;
  background-color: #F1F4F8;
  border-radius: 5px;
}

.titulo-container {
  width: 150px;
  margin: auto;
  padding-top: 16px;
}

.titulo-container p {
  font-size: 12px;
  text-align: center;
}

.photo-container {
  padding-top: 22px;
}

.photo-border {
  display: block;
  position: relative;
  width: 52px;
  height: 40px;
  background: #EB5E00;
  box-sizing: border-box;
  clip-path: circle(40% at 50% 50%);
  margin:auto;
}

.photo {
  position: absolute;
  top: 1px; 
  left: 1px; 
  width: 50px;
  clip-path: circle(40% at 50% 50%);
  
}