
.top-menu {
  width: 100%;
  height: 80px;
  background-color: #fff;
}

.search-container {
  width: 30%;
  height: 100%;
}

.menu-options-container {
  width: 70%;
  height: 100%;
}

.menu-placeholder {
  margin-right: 80px;
  float: right;
  height: 100%;
}

.top-menu-item {
  margin-left: 40px;
}

.top-menu-text {
  font-size: 17px;
  font-family: 'Rubik', sans-serif;
  font-weight: normal;
  cursor: pointer;
}

.disabled {
  cursor: default !important;
  color: #A5A6B5;
}

.top-menu-text p {
  margin: 0;
}

.search-placeholder {
  width: 80%;
  height: 100%;
  margin-left: 10%;
}

.search-form {
  width: 100%;
  height: 38px;
}

.search-form > .form-group {
  margin: 0px;
  height: 100%;
}

.search-input {
  box-sizing: border-box;
  height: 100%;
  width: 100% !important;
  border: 0.6px solid #A5A6B5 !important;
  border-radius: 19px !important;
}

.search-icon {
  position: relative;
  bottom: 34px;
  left: 90%;
}

.menu-margin {
  margin-left: 5px;
}

.menu-profile-image {
  width: 50px;
  clip-path: circle(40% at 50% 50%);
}

.menu-profile-button {
  border: none;
  background: #fff;
}

.menu-profile-button:focus {
  outline: 0;
}

.profile-container {
  width: 195px;
  height: auto;
  background-color: #fff;
  position: absolute;
  margin-top: 10px;
  right: 80px;
  margin-left: 25px;
  border-radius: 3px;
  z-index: 1000;
}

.box-shadow {
  -webkit-box-shadow: 0.1px 0.1px 4px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 0.1px 0.1px 4px 0px rgba(0,0,0,0.2);
  box-shadow: 0.1px 0.1px 4px 0px rgba(0,0,0,0.2);
}

.user-name-text {
  font-size: 13px;
  font-family: 'Rubik', sans-serif;
  font-weight: normal;
  cursor: default;
}

.sub-item-text {
  font-size: 13px;
  font-family: 'Rubik', sans-serif;
  font-weight: normal;
  cursor: pointer;
}

.btn-profile-menu {
  background-color: #fff;
  font-size:14px !important;
  font-family: 'Rubik', sans-serif;
  font-weight: normal;
  height: 40px;
  width: 137px;
}

.sub-item-container {
  margin-left: 25px;
  height: 47px;
  line-height: 40px;
  vertical-align: middle;
  
}

.sub-separator-container {
  margin-left: 25px;
  margin-right: 25px;
}

.separator-menu {
  border-top: 1px solid #DEDFE9;
}