.profile-form {
  width: 900px;
  padding-left: 95px;
  padding-top: 28px;
}

.label {
  font-size: 14px;
}

input.form-control, select.form-control {
  font-size: 14px !important;
}

.btn-save {
  font-size: 14px !important;
  font-family: 'Rubik', sans-serif;
  font-weight: normal;
  width: 161px;
  height: 44px;
}