.todo-form .form-control:focus {
    box-shadow: none;
}

.todo-form .form-control{
    border: none;
}

.todo-form #title.form-control{
    font-weight: bold;
}

.todo-form .SingleDatePickerInput__withBorder {
    border: none !important;
}

.todo-form .DateInput_input {
    border-radius: .25rem !important;
    height: 35px !important;
    border: 1px solid #ced4da !important;
    font-size: 14px;
    font-weight: inherit;
}

.todo-form .badge{
    font-size: 1rem !important;
    font-weight: normal;
}